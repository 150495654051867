<template>
  <!-- 直播列表页 -->
  <div class="container">
    <category @subjectId="subjectId" @subType="subType"  />
    <div class="hh_con_wrap">
      <div class="hh_con common_inner">
        <live-com :type="'liveType'" :livedata="livedata" @getVerCode="getVerCode"/>
        <none-data v-if="livedata.length <= 0" :searchType="searchType"></none-data>
        <pagination class="pagination jumpBtn" v-if="totalCount > 0"  :pageSize="liveData.pageSize" :total="totalCount" @pageChange="pageChange" @sizeChange="sizeChange" />
      </div>
    </div>
    <courseVerificationCode :courseId="courseId" />
  </div>
</template>
<script>
import API from '@/apis/studentsEndAPI/liveListAPI'
import liveCom from '@/components/studentsComponent/liveCom'
import category from './components/category'
import courseVerificationCode from "@/components/backStageComponent/courseVerificationCode"
import Pagination from '@comps/backStageComponent/pagination'
import { EventBus } from '@/assets/js/eventBus'
import noneData from '@comps/backStageComponent/noneData'
import CommonUtils from "@/utils";
export default {
  name: 'liveList',
  components: { category, liveCom, Pagination,noneData,courseVerificationCode },
  data() {
    return {
      courseId: '',
      searchType: 1,
      liveType: 1,
      totalCount: 0,
      livedata: [],
      liveData: {
        courseType: 1, //课程类型
        page: 1,
        pageSize: 20,
        createStartTime: '',
        createEndTime: '',
        state: '',
        subjectId: '', //学科id
        keyword: '',
        labels: '',//标签
      },
    }
  },
  watch: {
    '$store.state.student.value': {
      handler(val) {
        if (val == '') {
          this.liveData.keyword = ''
          this.init()
        }
      },
      deep: true,
    },
  },
  mounted() {
    if(this.$store.state.student.value) {
      this.liveData.keyword = this.$store.state.student.value
      this.init()
    }
    if (sessionStorage.getItem('livedata')) {
      let data = JSON.parse(sessionStorage.getItem('livedata'))
      if(data.dataList.length > 0) {
        this.totalCount = data.rowCount
        this.livedata = data.dataList
      }
    }else if(sessionStorage.getItem('nodata')) {
      this.livedata = []
    }else {
      this.init()
    }
    EventBus.$emit('curIndex', 1)
  },
  beforeDestroy() {
    sessionStorage.removeItem('secondList')
    sessionStorage.removeItem('dibbledata')
    sessionStorage.removeItem('nodata')

  },
  beforeRouteLeave(to, from, next) {
    if (from.path == '/liveList') sessionStorage.setItem('flag', JSON.stringify(true))
    if (to.path == '/home') sessionStorage.removeItem('flag')
    if(to.fullPath == '/dibbleList') {
      this.$EventBus.$emit('fromLive' , true)
    }
    next()
  },
  methods: {
    getVerCode(val) {
      this.courseId = val.courseId
      this.$store.state.isVerifi = true
    },
    async init() {
      console.log(this.liveData);
      let res = await API.getLiveList(CommonUtils.parseToParams(this.liveData))
      if(res && !res.status) {
        this.totalCount = res.rowCount
        this.livedata = res.dataList
      }
    },
    pageChange(val) {
      if (val) {
        this.liveData.page = val
        this.init()
      }
    },
    sizeChange(val) {
      if (val) {
        this.liveData.page = val
        this.init()
      }
    },
    subjectId(subjectId, type) {
      if (subjectId) {
        this.liveData.subjectId = subjectId
        this.init()
      } else if (type == 'all') {
        this.liveData.subjectId = ''
        this.liveData.page = 1
        this.init()
      }
    },
    subType(subType,type) {
      if(subType) {
        this.liveData.labels = subType
        this.init()
      }else if(type == 'all') {
        this.liveData.labels = ''
        this.init()
      }

    }
  },
}
</script>
<style lang='scss' scoped>
@import "src/assets/css/common.scss";
.container {
	min-height: calc(100vh - 303px);
	width: 100%;
	background: #f7faff;
	.hh_con_wrap {
		width: 100%;
	}
}
</style>
